
const state = {
  topic_embed: "llm-query/org",
  topic_chat: "llm-ssn-query/org",
  embed: false,
  setFiles: undefined,
  api: undefined, // set in on_load()
  // max_tokens: 4096,
  // min_match: 0.75,
  // max_tokens: 8192,
  // min_match: 0.5,
};

export function setupLLMApiSession(api, setData) {
  state.api = api;

  if (setData) {
    state.setFiles = setData;
  }

  setupLLMSession();
}

function setupLLMSession() {
  state.api.call("llm-ssn-start/org", {}, (msg, error) => {
    if (msg && msg.sid) {
      state.llm_ssn = msg.sid;
      // heartbeat ~ssn topic to keep it from being cleaned up
      // this will end when the page or tab reloads or is closed
      setInterval(() => {
        state.api.publish(`~${msg.sid}`, { sid: msg.sid });
      }, 10000);
    } else {
      console.log({ llm_session_error: error, msg });
    }
  });
}

export function queryLLM(query, handler, subscriber) {
  const start = Date.now();
  const rand = Math.round(Math.random() * 0xffffffff).toString(36);
  const topic = `~${start.toString(36)}:${rand}`;
  // ephemeral ~topic for receiving llm token stream
  const tokens = [];
  state.api.subscribe(
    topic,
    (token) => {
      tokens.push(token);
      subscriber?.(tokens.join(""));
    },
    120,
  );

  queryEmbed(query, topic, (answer) => {
    handler?.(answer);
  });
}

function queryChat(query, topic, handler) {
  state.api.call(
    state.topic_chat,
    {
      sid: state.llm_ssn,
      query,
      topic,
    },
    (msg) => {
      if (msg) {
        console.log({ llm_said: msg });
        handler?.(msg.answer);
      } else {
        console.log({ llm_said: msg });
        handler?.("there was an error processing this query");
      }
    },
  );
}

function queryEmbed(query, topic, handler) {
  state.api.call(
    "docs-query/$",
    {
      query,
      topic,
      llm: state.topic_embed,
    },
    (msg) => {
      if (msg) {
        console.log({ llm_said: msg });
        handler?.(msg.answer);
      } else {
        console.log({ llm_said: msg });
        handler?.("there was an error processing this query");
      }
    },
  );
}

