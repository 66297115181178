import { createContext, useContext } from "react";

// Create a context with a default value of null for the WebSocket connection
const WebSocketApiContext = createContext(null);

export const useSocketContext = () => {
  return useContext(WebSocketApiContext);
};

export default WebSocketApiContext;
