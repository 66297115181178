import { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { getActiveUsers } from "api/proxy/auth-api";

import { ContentCard } from "components/cards";

const ActiveUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getActiveUsers((msg) => {
      setActiveUsers(msg?.active);
      setIsLoading(false);
    });
  }, []);

  // Table's column
  const columns = useMemo(
    () => [
      {
        title: "Email",
        key: "user",
        dataIndex: "user",
      },
      {
        title: "Org Admin",
        key: "org_admin",
        dataIndex: "org_admin",
        render: (value) =>
          value ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: 25 }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: 25 }} />
          ),
      },
    ],
    []
  );

  return (
    <ContentCard title="Active Users">
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={activeUsers}
        rowKey={(record) => record.key + record.name}
      />
    </ContentCard>
  );
};

export default ActiveUsers;
