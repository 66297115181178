import Styles from "./styles/section.module.scss";

const Section = ({ heading, type, extra, children }) => {
  return (
    <section className={Styles.section}>
      <div className={Styles.heading}>
        {type === "main" ? <h1>{heading}</h1> : <h2>{heading}</h2>}
        {extra && <div className={Styles.extra}>{extra}</div>}
      </div>
      {children}
    </section>
  );
};

export default Section;
