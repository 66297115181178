import { Select as AntdSelect } from "antd";

import Styles from "./styles/select.module.scss";

const { Option } = AntdSelect;

const Select = ({ styles, className, children, ...props }) => {
  return (
    <>
      {children ? (
        <AntdSelect
          style={styles || {}}
          className={`${Styles.select} ${className || ""}`}
          {...props}
        >
          {children}
        </AntdSelect>
      ) : (
        <AntdSelect
          style={styles || {}}
          className={`${Styles.select} ${className || ""}`}
          {...props}
        />
      )}
    </>
  );
};

Select.Option = Option;

export default Select;
