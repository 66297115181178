import { Button as AntdButton } from "antd";

import Styles from "./styles/button.module.scss";

const Button = ({ children, className, ...props }) => {
  return (
    <AntdButton
      className={`${Styles.button} ${className ? className : ""}`}
      {...props}
    >
      {children}
    </AntdButton>
  );
};

export default Button;
