export const state = {
  api: undefined, // set in on_load()
  setAppList: undefined,
};

export function setupAdminAPISession(api, setAppList) {
  state.api = api;
  if (setAppList) {
    state.setAppList = setAppList;
  }
}

export function getApps(data, handler) {
  state.api.call("app_list", data, (msg, error, topic) => {
    handler?.(msg, error, topic);
  });
}

export function getApp(data, handler) {
  state.api.call("app_get", data, (msg) => {
    handler?.(msg);
  });
}

export function createApps(data, handler) {
  state.api.call("app_create", data, (msg, error, topic) => {
    handler?.(msg, error, topic);
  });
}

export function deleteApp(data, handler) {
  state.api.call("app_delete", data, (msg) => {
    handler?.(msg);
  });
}

export function updateApp(data, handler) {
  state.api.call("app_update", data, (msg) => {
    handler?.(msg);
  });
}

export function checkAdminStatus(data, handler) {
  state.api.call("service_check", data, (msg) => {
    handler?.(msg);
  });
}
